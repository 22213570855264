<template>
  <div promotion-home :dir="isRtl ? 'rtl' : 'ltr'">
    <div class="contents-wrapper">
      <PromotionHeader v-if="showHeader" />
      <div class="promotion-contents-holder">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import 'swiper/swiper-bundle.css';
import { state } from '@shared/utils/storeUtils';
import { siteIds } from '@/constants/base/siteMap';
import PromotionHeader from '@/views/components/pages/promotion/PromotionHeader.vue';

export default {
  name: 'PromotionHome',
  components: { PromotionHeader },
  data() {
    return {
      promotionInfo: null,
      sessionExpired: false,
      myInfo: null,
    };
  },
  computed: {
    env: state('env', 'env'),
    site: state('env', 'site'),
    token: state('user', 'token'),
    language: state('query', 'selectedLang'),
    showHeader() {
      const hasClientQuery = !!Object.keys(this.$route.query).find(v => v === 'isFromClientApp');
      this.$store.commit('promotion/setIsClient', hasClientQuery);
      return !hasClientQuery;
    },
  },
  async mounted() {
    if (this.site === siteIds.GGVEGAS) await this.replaceRouteName('NotProvide');
    await this.$promotion.setScope(this);
  }
};
</script>

<style lang="less">
// [celina] temporary code before less cleanup
@import "@/less/tpm";
html { .lh(normal); font-family: 'Noto Sans', sans-serif; }
body { .bgc(#171717); .c(#fff); font-family: 'Noto Sans', sans-serif; }
* { word-break: initial; word-wrap: initial; font-family: inherit; }
[font-icon] { .vam(); }
#app {
  .contents-wrapper { .c(inherit);
    .contents-holder { .p(0); text-align: initial; .max-w(100%); .mh-c(); }
  }
}
.title { .m(); font-size: initial; font-weight: initial; text-shadow: none;
  > * { display: initial; .m(); font-size: initial; font-weight: initial; .c(inherit); }
}
.no-list { .w(); .p(); .br(0); .bgc(); .fs(inherit); .regular(); .c(inherit); }
// [celina] end of temporary code before less cleanup
</style>
<style scoped lang="less">
@import "@/less/tpm";
[promotion-home] { .bgc(#171717); .c(@c-white); .lh(initial); word-break: initial; word-wrap: initial;  font-family: 'Noto Sans', sans-serif;
  .contents-wrapper { .flex(); .flex-dc(); .min-h(100vh); }
  [app-header], [notification] { flex: 0; }
  .promotion-contents-holder { flex: 1; .flex(); .justify-center(); align-items: stretch; .wf(); .max-w(1030); .mh-c();
    > div, .contents { .wf(); }
    [align-center-container] { .h(); }

    @media (@tp-up) {
      .p(20, 30);
    }
  }
}
</style>
